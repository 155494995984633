<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="4"
                lg="6"
                md="6"
                sm="4"
            >
                <h3>Dashboard</h3>
            </v-col>
        </v-row>

        <v-row>

            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-card
                    class="d-flex flex-wrap mb-6"
                    flat
                    tile
                >
                    <!-- LEADS -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card leads_alpha">
                        <v-card-title class="text-h6 leads d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-account-tie</v-icon>
                                <label class="pipelineLabelTitle">Leads</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.leads.total }} </label>
                                <label class="labelHoldResume">Total</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.leads.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        ref="chartAdminLeads"
                                        :chartId="'dashboardAdminLeads'"
                                        :chartData="dashboardAdminLeads"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <!-- FRONT DESK -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card frontdesk_alpha">
                        <v-card-title class="text-h6 frontdesk d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-face-agent</v-icon>
                                <label class="pipelineLabelTitle">Front Desk</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.frontDesk.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.frontDesk.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.frontDesk.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminFrontDesk'"
                                        :chartData="dashboardAdminFrontDesk"
                                        :chartOptions="{
                                            legend: {
                                                position: 'bottom'
                                            }
                                        }"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                
                    <!-- SALES -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card sales_alpha">
                        <v-card-title class="text-h6 sales d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-account-tie</v-icon>
                                <label class="pipelineLabelTitle">Sales</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.sales.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.sales.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.sales.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminSales'"
                                        :chartData="dashboardAdminSales"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <!-- DRAFTING -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card drafting_alpha">
                        <v-card-title class="text-h6 drafting d-flex justify-space-between">

                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-note-edit</v-icon>
                                <label class="pipelineLabelTitle">Drafting</label>
                            </div>                        

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.drafting.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <!-- <br /> -->
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.drafting.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title>
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.drafting.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminDrafting'"
                                        :chartData="dashboardAdminDrafting"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- PERMITTING -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card permitting_alpha">
                        <v-card-title class="text-h6 permitting d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-image-lock-outline</v-icon>
                                <label class="pipelineLabelTitle">Permitting</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.permitting.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.permitting.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.permitting.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminPermitting'"
                                        :chartData="dashboardAdminPermitting"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <!-- CONSTRUCTION -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card construction_alpha">
                        <v-card-title class="text-h6 construction d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-account-hard-hat-outline</v-icon>
                                <label class="pipelineLabelTitle">Construction</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.construction.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <!-- <br /> -->
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.construction.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.construction.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminConstruction'"
                                        :chartData="dashboardAdminConstruction"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <!-- DEPARTMENTS / SERVICES -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card service_alpha">
                        <v-card-title class="text-h6 service d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-briefcase-outline</v-icon>
                                <label class="pipelineLabelTitle">Departments</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.services.totalOpen }} </label>
                                <label class="labelHoldResume">Open</label>
                                <label class="pipelineLabelTitle"> | </label>
                                <!-- <br /> -->
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.services.totalHold }} </label>
                                <label class="labelHoldResume">On Hold</label>
                            </div>

                        </v-card-title> 
                        <v-card-text 
                            class="pipelineDetails"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in dashboardAdmin.services.listItens" :key="index"
                                    >
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="4"
                                            sm="2"
                                            class="alignValue"
                                        >
                                            <label class="pipelineLabelValue">{{ item.value }} </label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="8"
                                            md="8"
                                            sm="6"
                                            class="alignValueLabel"
                                        >
                                            <label class="pipelineLabel">{{ item.description }}</label>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="12"
                                    sm="12"
                                >
                                    <DoughnutChart
                                        :chartId="'dashboardAdminServices'"
                                        :chartData="dashboardAdminServices"
                                    />
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                    
                    <!-- INSPECTIONS -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card inspections_alpha">
                        <v-card-title class="text-h6 inspections d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-magnify-expand</v-icon>
                                <label class="pipelineLabelTitle">Inspections</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.inspectionsByResponsible.total }} </label>
                                <label class="labelHoldResume">Total</label>
                            </div>

                        </v-card-title> 

                        <v-tabs
                            v-model="tabInspection"
                            fixed-tabs
                        >
                            <v-tab key="1">
                                By Phase
                            </v-tab>
                            <v-tab key="2">
                                By Status
                            </v-tab>
                            <v-tab key="3">
                                By User
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tabInspection" style="background-color: transparent !important;">

                            <!-- BY PHASE -->
                            <v-tab-item key="1">

                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.inspectionsByPhase.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminInspectionsByPhase'"
                                                :chartData="dashboardAdminInspectionsByPhase"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>

                            </v-tab-item>

                            <!-- BY STATUS -->
                            <v-tab-item key="2">

                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.inspectionsByStatus.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminInspectionsByStatus'"
                                                :chartData="dashboardAdminInspectionsByStatus"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-tab-item>

                            <!-- BY USER -->
                            <v-tab-item key="3">

                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.inspectionsByResponsible.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminInspectionsByResponsible'"
                                                :chartData="dashboardAdminInspectionsByResponsible"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>

                            </v-tab-item>

                        </v-tabs-items>
                    </v-card>
                    
                    <!-- WORK ORDERS -->
                    <v-card :width="$vuetify.breakpoint.mobile ? '100%': '48%'" class="margin-card work_order_alpha">
                        <v-card-title class="text-h6 work_order d-flex justify-space-between">
                            
                            <div>
                                <v-icon left class="pipelineIconTitle">mdi mdi-briefcase-outline</v-icon>
                                <label class="pipelineLabelTitle">Work Orders</label>
                            </div>

                            <div style="text-align: right;">
                                <label class="labelHoldResumeValue"> {{ dashboardAdmin.workOrderByResponsible.total }} </label>
                                <label class="labelHoldResume">Total</label>
                            </div>

                        </v-card-title> 

                        <v-tabs
                            v-model="tabWorkOrder"
                            fixed-tabs
                        >
                            <v-tab key="1">
                                By Category
                            </v-tab>
                            <v-tab key="2">
                                By Status
                            </v-tab>
                            <v-tab key="3">
                                By Date
                            </v-tab>
                            <v-tab key="4">
                                By User
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tabWorkOrder" style="background-color: transparent !important;">

                            <!-- BY CATEGORY -->
                            <v-tab-item key="1">
                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.workOrderByCategory.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminWorkOrderByCategory'"
                                                :chartData="dashboardAdminWorkOrderByCategory"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-tab-item>

                            <!-- BY STATUS -->
                            <v-tab-item key="2">
                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.workOrderByStatus.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminWorkOrderByStatus'"
                                                :chartData="dashboardAdminWorkOrderByStatus"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-tab-item>

                            <!-- BY AGING -->
                            <v-tab-item key="3">
                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.workOrderByAging.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminWorkOrderByAging'"
                                                :chartData="dashboardAdminWorkOrderByAging"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-tab-item>

                            <!-- BY USER -->
                            <v-tab-item key="4">
                                <v-card-text 
                                    class="pipelineDetails"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <v-row 
                                                v-for="(item, index) in dashboardAdmin.workOrderByResponsible.listItens" :key="index"
                                            >
                                                <v-col 
                                                    cols="2"
                                                    lg="2"
                                                    md="4"
                                                    sm="2"
                                                    class="alignValue"
                                                >
                                                    <label class="pipelineLabelValue">{{ item.value }} </label>
                                                </v-col>
                                                <v-col 
                                                    cols="6"
                                                    lg="8"
                                                    md="8"
                                                    sm="6"
                                                    class="alignValueLabel"
                                                >
                                                    <label class="pipelineLabel">{{ item.description }}</label>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                        >
                                            <DoughnutChart
                                                :chartId="'dashboardAdminWorkOrderByResponsible'"
                                                :chartData="dashboardAdminWorkOrderByResponsible"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>

                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import DoughnutChart from '@/components/Graphics/Doughnut.vue'
    export default ({

        mixins: [Helpers],

        components: {
            DoughnutChart
        },

        data: () => ({

            filter: {
                fastSearch: ''
            },

            graphBorderColor: 'rgb(255, 255, 255, 0.3)',

            totalEstimatedBudget: "$ 0,00",

			dashboardAdmin: {
                leads: [],
                frontDesk: [],
                sales: [],
                drafting: [],
                permitting: [],
                construction: [],
                services: [],
                inspectionsByResponsible: [],
                inspectionsByPhase: [],
                inspectionsByStatus: [],
                workOrderByResponsible: [],
                workOrderByCategory: [],
                workOrderByStatus: [],
                workOrderByAging: []
            },
            
            // defaultColorLeads: '198, 40, 40',
            defaultColorLeads: 0,
			dashboardAdminLeads: {
                labels: [],
                datasets: []
            },

            // defaultColorFrontDesk: '1, 131, 136',
            defaultColorFrontDesk: 182,
			dashboardAdminFrontDesk: {
                labels: [],
                datasets: []
            },
            
            // defaultColorSales: '106, 27, 154',
            defaultColorSales: 282,
			dashboardAdminSales: {
                labels: [],
                datasets: []
            },
            
            // defaultColorDrafting: '69, 39, 160',
            defaultColorDrafting: 250,
			dashboardAdminDrafting: {
                labels: [],
                datasets: []
            },
            
            // defaultColorPermitting: '40, 53, 147',
            defaultColorPermitting: 234,
			dashboardAdminPermitting: {
                labels: [],
                datasets: []
            },
            
            // defaultColorConstruction: '21, 101, 192',
            defaultColorConstruction: 207,
			dashboardAdminConstruction: {
                labels: [],
                datasets: []
            },
            
            // defaultColorServices: '46, 125, 50',
            defaultColorServices: 122,
			dashboardAdminServices: {
                labels: [],
                datasets: []
            },

            tabInspection: null,
            tabWorkOrder: null,
            
            // defaultColorInspections: '232, 4, 248',
            defaultColorInspections: 290,
			dashboardAdminInspectionsByResponsible: {
                labels: [],
                datasets: []
            },
            
			dashboardAdminInspectionsByPhase: {
                labels: [],
                datasets: []
            },
            
			dashboardAdminInspectionsByStatus: {
                labels: [],
                datasets: []
            },
            
            // defaultColorWorkOrder: '4, 191, 248',
            defaultColorWorkOrder: 192,
			dashboardAdminWorkOrderByResponsible: {
                labels: [],
                datasets: []
            },
            
			dashboardAdminWorkOrderByCategory: {
                labels: [],
                datasets: []
            },
            
			dashboardAdminWorkOrderByStatus: {
                labels: [],
                datasets: []
            },
            
			dashboardAdminWorkOrderByAging: {
                labels: [],
                datasets: []
            },

            legend: {
                position: 'bottom'
            }
                
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            backgroundColors(data, baseColor) {
                // let alpha = 0.80;
                // return data.map(
                //     () => {
                //         let color = `rgb(${rgbColor}, ${alpha})`
                //         alpha -= 0.15;
                //         return color;
                //     }
                // )

                const baseHue = baseColor; // Valor de matiz para vermelho
                const saturation = '100%'; // Saturação constante
                const lightnessStep = 10; // Incremento para variar a luminosidade
                const numOfColors = data.length; // Número de cores a serem geradas

                let colors = [];

                for (let i = 0; i < numOfColors; i++) {
                    let lightness = `${i * lightnessStep}%`;
                    let color = `hsl(${baseHue}, ${saturation}, ${lightness})`;
                    colors.push(color);
                }

                return colors;
            },

            async loadLeads() {

                let dataLeads = this.dashboardAdmin.leads;
                let labels = dataLeads.listItens.map( (item) => item.description )
                let data = dataLeads.listItens.map( (item) => item.value )
                this.dashboardAdminLeads = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorLeads),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadFrontDesk() {

                let dataFrontDesk = this.dashboardAdmin.frontDesk;
                let labels = dataFrontDesk.listItens.map( (item) => item.description )
                let data = dataFrontDesk.listItens.map( (item) => item.value )
                this.dashboardAdminFrontDesk = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorFrontDesk),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadSales() {

                let dataSales = this.dashboardAdmin.sales;
                let labels = dataSales.listItens.map( (item) => item.description )
                let data = dataSales.listItens.map( (item) => item.value )
                this.dashboardAdminSales = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorSales),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadDrafting() {

                let dataDrafting = this.dashboardAdmin.drafting;
                let labels = dataDrafting.listItens.map( (item) => item.description )
                let data = dataDrafting.listItens.map( (item) => item.value )
                this.dashboardAdminDrafting = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorDrafting),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadPermitting() {

                let dataPermitting = this.dashboardAdmin.permitting;
                let labels = dataPermitting.listItens.map( (item) => item.description )
                let data = dataPermitting.listItens.map( (item) => item.value )
                this.dashboardAdminPermitting = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorPermitting),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadConstruction() {

                let dataConstruction = this.dashboardAdmin.construction;
                let labels = dataConstruction.listItens.map( (item) => item.description )
                let data = dataConstruction.listItens.map( (item) => item.value )
                this.dashboardAdminConstruction = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorConstruction),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadServices() {

                let dataServices = this.dashboardAdmin.services;
                let labels = dataServices.listItens.map( (item) => item.description )
                let data = dataServices.listItens.map( (item) => item.value )
                this.dashboardAdminServices = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorServices),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }
            },

            async loadInspections() {

                let dataInspectionsByResponsible = this.dashboardAdmin.inspectionsByResponsible;
                let labels = dataInspectionsByResponsible.listItens.map( (item) => item.description )
                let data = dataInspectionsByResponsible.listItens.map( (item) => item.value )
                this.dashboardAdminInspectionsByResponsible = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorInspections),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }

                let dataInspectionsByPhase = this.dashboardAdmin.inspectionsByPhase;
                labels = dataInspectionsByPhase.listItens.map( (item) => item.description )
                data = dataInspectionsByPhase.listItens.map( (item) => item.value )
                this.dashboardAdminInspectionsByPhase = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorInspections),
                            data
                        }
                    ]
                }

                let dataInspectionsByStatus = this.dashboardAdmin.inspectionsByStatus;
                labels = dataInspectionsByStatus.listItens.map( (item) => item.description )
                data = dataInspectionsByStatus.listItens.map( (item) => item.value )
                this.dashboardAdminInspectionsByStatus = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorInspections),
                            data
                        }
                    ]
                }
            },

            async loadWorkOrder() {

                let dataWorkOrderByResponsible = this.dashboardAdmin.workOrderByResponsible;
                let labels = dataWorkOrderByResponsible.listItens.map( (item) => item.description )
                let data = dataWorkOrderByResponsible.listItens.map( (item) => item.value )
                this.dashboardAdminWorkOrderByResponsible = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorWorkOrder),
                            borderColor: this.graphBorderColor,
                            data
                        }
                    ]
                }

                let dataWorkOrderByCategory = this.dashboardAdmin.workOrderByCategory;
                labels = dataWorkOrderByCategory.listItens.map( (item) => item.description )
                data = dataWorkOrderByCategory.listItens.map( (item) => item.value )
                this.dashboardAdminWorkOrderByCategory = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorWorkOrder),
                            data
                        }
                    ]
                }

                let dataWorkOrderByStatus = this.dashboardAdmin.workOrderByStatus;
                labels = dataWorkOrderByStatus.listItens.map( (item) => item.description )
                data = dataWorkOrderByStatus.listItens.map( (item) => item.value )
                this.dashboardAdminWorkOrderByStatus = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorWorkOrder),
                            data
                        }
                    ]
                }

                let dataWorkOrderByAging = this.dashboardAdmin.workOrderByAging;
                labels = dataWorkOrderByAging.listItens.map( (item) => item.description )
                data = dataWorkOrderByAging.listItens.map( (item) => item.value )
                this.dashboardAdminWorkOrderByAging = {
                    labels,
                    datasets: [
                        {
                            backgroundColor: this.backgroundColors(data, this.defaultColorWorkOrder),
                            data
                        }
                    ]
                }
            },
            
            async getRegisters() {

                this.dashboardAdmin = await this.$store.dispatch("dashboardModule/DashboardAdmin");
                this.loadFrontDesk();
                this.loadSales();
                this.loadDrafting();
                this.loadPermitting();
                this.loadConstruction();
                this.loadServices();
                this.loadInspections();
                this.loadWorkOrder();
                this.loadLeads();
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },

        
    })
</script>

<style scoped>

    label {
        font-size: 14px !important;
        color: #5e5e5e;
    }

    .pipelineDetails {
        margin-top: 15px;
    }

    .pipelineIconTitle {
        color: white !important;
    }

    .pipelineLabelTitle {
        color: white !important;
        font-size: 17px;
    }

    .labelHoldResume {
        color: rgb(255, 255, 255, 0.8) !important;
        font-size: 17px;
    }

    .labelHoldResumeValue {
        color: white !important;
        font-size: 17px !important;
        margin-right: 5px;
    }

    .pipelineLabel {
        font-size: 17px;
    }

    .pipelineLabelValue {
        color: var(--color__main) !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .pipelineLabelRedValue {
        color: var(--color__preDig_alert) !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .pipelineLabelValueBlank {
        color: transparent !important;
        font-weight: bold !important; 
        font-size: 20px !important;
    }

    .alignValue {
        margin-left: 15px;
        text-align: center;
    }

    .alignValueLabel {
        margin-left: 35px;
    }

    .frontdesk {
        background-color: var(--color__construction_dashboard_predig);
    }

    .frontdesk_alpha {
        background-color: var(--color__construction_dashboard_predig_alpha);
    }

    .leads {
        background-color: var(--color__construction_dashboard_frontend);
    }

    .leads_alpha {
        background-color: var(--color__construction_dashboard_frontend_alpha);
    }

    .sales {
        background-color: var(--color__construction_dashboard_grading_plumbing);
    }

    .sales_alpha {
        background-color: var(--color__construction_dashboard_grading_plumbing_alpha);
    }

    .drafting {
        background-color: var(--color__construction_dashboard_tiledeck);
    }

    .drafting_alpha {
        background-color: var(--color__construction_dashboard_tiledeck_alpha);
    }

    .permitting {
        background-color: var(--color__construction_dashboard_equipment_electric);
    }

    .permitting_alpha {
        background-color: var(--color__construction_dashboard_equipment_electric_alpha);
    }

    .construction {
        background-color: var(--color__construction_dashboard_prep_plaster);
    }

    .construction_alpha {
        background-color: var(--color__construction_dashboard_prep_plaster_alpha);
    }

    .service {
        background-color: var(--color__construction_dashboard_fence);
    }

    .service_alpha {
        background-color: var(--color__construction_dashboard_fence_alpha);
    }

    .inspections {
        background-color: var(--color__construction_dashboard_inspections);
    }

    .inspections_alpha {
        background-color: var(--color__construction_dashboard_inspections_alpha);
    }

    .work_order {
        background-color: var(--color__construction_dashboard_work_order);
    }

    .work_order_alpha {
        background-color: var(--color__construction_dashboard_work_order_alpha);
    }

    .margin-card {
        margin: 10px;
    }

    div[class^='col-'] {
        padding-top: 10px;
        padding-bottom: 10px;
    }
</style>