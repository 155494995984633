<template>
	<Doughnut
		:chart-options="chartOptions"
		:chart-data="chartData"
		:chart-id="chartId"
		:dataset-id-key="datasetIdKey"
		:plugins="plugins"
		:css-classes="cssClasses"
		:styles="styles"
		:width="width"
		:height="height"
	/>
</template>

<script>
	import { Doughnut } from 'vue-chartjs/legacy'

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		ArcElement,
		CategoryScale
	} from 'chart.js'

	ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

	export default {
		name: 'DoughnutChart',
		components: {
			Doughnut
		},
		props: {
			
			chartId: {
				type: String,
				default: 'doughnut-chart'
			},

			datasetIdKey: {
				type: String,
				default: 'label'
			},

			width: {
				type: Number,
				default: 300
			},

			height: {
				type: Number,
				default: 300
			},

			cssClasses: {
				default: '',
				type: String
			},

			styles: {
				type: Object,
				default: () => {}
			},

			plugins: {
				type: Object,
				default: () => {}
			},

			chartData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				chartOptions: {
					title: {
					display: false,
					text: 'Chart.js Doughnut Chart'
					},
					responsive: true,
					maintainAspectRatio: false,

					animation: {
						animateScale: true,
						animateRotate: true
					},

					plugins: {
						legend: {
							position: 'bottom'
						},
						tooltips: {
							callbacks: {
								label: function(tooltipItem, data) {
									var dataset = data.datasets[tooltipItem.datasetIndex];
									var total = dataset.data.reduce(function(previousValue, currentValue) {
										return previousValue + currentValue;
									});
									var currentValue = dataset.data[tooltipItem.index];
									var precentage = Math.floor(((currentValue/total) * 100)+0.5);
									return precentage + "%";
								}
							}
						},
					},
				}
			}
		}
	}
</script>
